
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import State from "@/components/reusable/State.vue";
import Address from "@/components/reusable/Address.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import BuildingTypes from "@/views/reusable/buy-insurance/house-insurance/building-types.json";

export default defineComponent({
  name: "own-building-addition-edit-step-1",
  components: {
    Field,
    ErrorMessage,
    State,
    Address,
    ImagesUploader,
  },
  props: {
    buildingReinstatementValueRate: Number,
    buildingReinstatementRatePublicId: String,
    lossOfRentValueRate: Number,
    lossOfRentRatePublicId: String,
    policyId: String,
    policySummary: Object,
  },
  data() {
    return {
      buildingTypes: BuildingTypes,
      totalPremium: 0.0 as number,
    };
  },
  methods: {
    premium(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);
      // const value = variables.removeComma(event.target.value);

      // Check if value is a number
      if (isNaN(value) == false) {
        // Calculate premium from vehicle value
        variables.premiumCalculator(
          event.target.ariaValueNow,
          value,
          event.target.ariaValueText
        );

        const payload = {
          value: value,
          rate: event.target.accessKey,
        } as object;

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(
            `${variables.CART_ADDITION_ROUTE}${this.policyId}/premium`,
            payload
          )
            .then(({ data }) => {
              //Assign data to form fields
              // Calculate premium from vehicle value
              variables.premiumCalculator(
                1,
                data.data,
                event.target.ariaValueText
              );

              // Append total premium value tot total premium field
              this.totalPremium = variables.totalPremiumValue();
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      } else {
        return;
      }
    },
    onLoadPremium(premium, rate, id) {
      setTimeout(() => {
        // Check if the phone item premium value is a number
        if (isNaN(premium) == false) {
          const payload = {
            value: premium,
            rate: rate,
          } as object;

          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(
              `${variables.CART_ADDITION_ROUTE}${this.policyId}/premium`,
              payload
            )
              .then(({ data }) => {
                // Calculate premium from vehicle value
                variables.premiumCalculator(1, data.data, id);
                // Append total premium value tot total premium field
                this.totalPremium = variables.totalPremiumValue();
              })
              .catch(function(error) {
                console.log(error);
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        } else {
          return;
        }
      }, 3000);
    },
  },
  computed: {
    mainPolicySummary() {
      return this.policySummary;
    },
  },
});
